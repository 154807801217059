/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { PageProps } from "gatsby";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";
import Seo from "@lekoarts/gatsby-theme-jodie/src/components/seo";
import GridItem from "./grid-item";
import locales from "@lekoarts/gatsby-theme-jodie/src/locales";
import { visuallyHidden } from "@lekoarts/gatsby-theme-jodie/src/styles/utils";

import useQuadriJson, { Quadro } from "../hooks/use-quadri-json";
import useQuadriImages, { ImgQuadri } from "../hooks/use-quadri-images";

type DataProps = {
  projects: {
    nodes: {
      shortTitle: string;
      slug: string;
      cover: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    }[];
  };
};

const Project: React.FC<PageProps<DataProps>> = ({
  data: { projects },
  location,
}) => {
  const { quadri } = useQuadriJson();
  const { nodes } = useQuadriImages();

  React.useEffect(() => {
    console.log(quadri);
    console.log(nodes);
  }, [quadri.length, nodes.length]);
  return (
    <Layout>
      <Seo title="Projects" pathname={location.pathname} />
      <h1 data-testid="page-title">Quadri</h1>
      <div
        sx={{
          display: `grid`,
          gridTemplateColumns: [`1fr`, `1fr 1fr`],
          gridAutoRows: `50vw`,
        }}
      >
        {projects.nodes.length > 0 ? (
          projects.nodes.map((project) => (
            <GridItem
              to={project.slug}
              key={project.slug}
              data-testid={project.shortTitle}
              data={quadri.filter(
                (q) => q.categoria === project.slug.split("/")[1]
              )}
              immagini={nodes.filter(
                (img) => img.relativeDirectory === project.slug.split("/")[1]
              )}
            >
              <GatsbyImage
                image={project.cover.childImageSharp.gatsbyImageData}
                alt=""
              />
              <span>{project.shortTitle}</span>
            </GridItem>
          ))
        ) : (
          <div sx={{ padding: 3 }}>
            No projects found at the location defined for "projectsPath"
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Project;
